.rdp-table {
  width: 100%;
  max-width: 100%;
  margin-top: 5px;
}

.rdp-head_cell,
.rdp-day {
  font-size: 14px;
}

.rdp-month .rdp-caption_start .rdp-caption_end {
  width: 100%;
}

.rdp-weeknumber,
.rdp-day {
  display: inline-block;
}

.rdp-day_selected {
  background-color: #0078d8 !important;
  border-radius: 5px;
  color: white;
  display: inline-flex;
}

.root > div:first-child > div:first-child {
  width: 100%;
}
