.rbc-event-label,
.rbc-day-slot .rbc-event-label {
  display: none !important;
}
.rbc-today {
  background-color: #fff;
}

.rbc-time-header {
  display: none;
}
.rbc-time-view {
  border-left: 0;
  border-right: 0;
}

.rbc-time-slot {
  margin-top: 20px;
}

.rbc-timeslot-group {
  height: 82px;
}

.rbc-time-content > .rbc-day-slot > .rbc-timeslot-group {
  padding-top: 14px;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #d0d7e4;
}

.rbc-time-content > * + * > * {
  border-left: 0;
}

.rbc-current-time-indicator {
  background-color: rgb(0, 120, 216);
}

.rbc-time-column {
  display: block;
}
