.time {
  background-color: white;
}

.react-datepicker__input-container .date_input,
.date_input {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #c9d2dc;
  outline-color: #3684ff;
  padding: 12px 12px 12px 36px;
  text-align: right;
  font-size: 0.95rem;
  width: 100px;
  min-width: 128px;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  padding-left: 10px;
  padding-top: 13px;
}

.react-datepicker__header {
  background-color: white;
}
