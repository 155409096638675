@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  font-weight: 100;
  src: url(./assets/fonts/Inter-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  src: url(./assets/fonts/Inter-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url(./assets/fonts/Inter-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url(./assets/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url(./assets/fonts/Inter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url(./assets/fonts/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url(./assets/fonts/Inter-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  src: url(./assets/fonts/Inter-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  src: url(./assets/fonts/Inter-Light.ttf) format('truetype');
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}

html {
  height: 100%;
  background-color: #fafbfc;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea::-webkit-input-placeholder {
  color: #8aa2b5;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #8aa2b5;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #8aa2b5;
}

textarea:-ms-input-placeholder {
  color: #8aa2b5;
}

textarea::placeholder {
  color: #8aa2b5;
}

#NUSAI_CommandBar_Container {
  width: 182px !important;
  height: 30px !important;
}

#NUSAI_CommandBar {
  width: 182px !important;
  height: 30px !important;
}

#NUSAI_CommandBar_GripLeft {
  height: 30px !important;
}

#NUSAI_CommandBar_GripRight {
  height: 30px !important;
}

#NUSAI_CommandBar_RecordContainer {
  height: 30px !important;
  width: 30px !important;
  margin-left: 12px !important;
}

#NUSAI_CommandBar_Record {
  height: 28px !important;
  width: 28px !important;
  top: 1px !important;
}

#NUSAI_CommandBar_GripMiddle {
  width: 148px !important;
  height: 30px !important;
  left: 47px !important;
}

.NUSAI_CommandBar_VolumeSegment {
  margin: 10px 4px 0 0 !important;
  width: 2px !important;
  height: 10px !important;
}

.NUSAI_CommandBar_Record_Off {
  background-size: contain !important;
}

.NUSAI_CommandBar_Record_On {
  background-size: contain !important;
}

.NUSAI_CommandBar_Record_Loading {
  background-size: contain !important;
}

#NUSAI_CommandBar_Options {
  height: 30px !important;
  width: 30px !important;
  top: 0px !important;
}

#NUSAI_CommandBar_RecordCanvas {
  height: 30px !important;
  width: 30px !important;
}

.NUSAI_CommandBar_Options_Off {
  background-size: contain !important;
}

button {
  cursor: pointer;
}

.NUSA_focusedElement {
  background: none !important;
}

td {
  padding: 0px;
}

@layer components {
  .btn {
    @apply flex items-center justify-center rounded-lg border font-semibold focus:outline-none focus:ring-2 disabled:cursor-not-allowed;
  }

  .btn-sm {
    @apply px-4 py-2 text-sm;
  }

  .btn-md {
    @apply px-4 py-2.5 text-sm;
  }

  .btn-lg {
    @apply px-4 py-2.5 text-base;
  }

  .btn-xl {
    @apply px-5 py-3 text-base;
  }

  .btn-2xl {
    @apply px-7 py-4 text-lg;
  }

  .btn-primary {
    @apply border-primary-600 bg-primary-600 text-white hover:border-primary-700 hover:bg-primary-700 focus:ring-purple-300 disabled:border-primary-200 disabled:bg-primary-200;
  }

  .btn-primary-ghost {
    @apply border-transparent bg-transparent text-primary-600 hover:border-primary-50 hover:bg-primary-50 focus:ring-purple-300 disabled:text-gray-300;
  }

  .btn-secondary {
    @apply border-primary-50 bg-primary-50 text-primary-700 hover:border-primary-100 hover:bg-primary-100 focus:ring-purple-300 disabled:border-primary-25 disabled:bg-primary-25 disabled:text-primary-300;
  }

  .btn-gray-ghost {
    @apply border-transparent bg-transparent text-gray-500 hover:bg-gray-50 hover:text-gray-600 focus:ring-gray-300 disabled:text-gray-300;
  }

  .btn-gray-link {
    @apply border-transparent bg-transparent p-0 text-gray-500  hover:text-gray-600 focus:ring-gray-300 disabled:text-gray-300;
  }

  .btn-white {
    @apply border-gray-300 bg-white text-primary-600 hover:bg-gray-50 focus:ring-gray-100 disabled:text-gray-300;
  }

  .btn-danger {
    @apply border-error-600 bg-error-600 text-white hover:border-error-700 hover:bg-error-700 focus:ring-error-300 disabled:border-error-200 disabled:bg-error-200;
  }

  .btn-danger-ghost {
    @apply bg-transparent text-error-700 hover:border-error-50 hover:bg-error-50 hover:text-error-800 focus:ring-error-300 disabled:text-gray-300;
  }

  .btn-danger-secondary {
    @apply border-error-50 bg-error-50 text-error-700  hover:border-error-100 hover:bg-error-100 focus:ring-error-300 disabled:border-error-25 disabled:bg-error-25 disabled:text-error-300;
  }

  .btn-danger-white {
    @apply border border-error-300 bg-white text-error-600 hover:bg-error-50 focus:ring-error-50 disabled:text-error-300;
  }
}
